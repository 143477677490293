import { Component, Vue } from 'nuxt-property-decorator'
import { namespace } from 'vuex-class'
import { Currency, Products, Stocks, Suggestions, Orderpath } from '@one/types'
import { Store } from 'vuex'
import { converters } from '@one/core'
import { BindingHelpers } from '~/node_modules/vuex-class/lib/bindings'
import { priceFormatter } from '~/utils/filters'
import { InjectReactive } from '~/utils/InjectReactive'
import ExternalUrl = Products.ExternalUrl

import Warehouse = Stocks.Warehouse
import { MappedProductStocks, StocksState, StockWarehousesMapping } from '~/store/stocks/types'
const { toPackagingQuantityPrice } = converters

export enum ProductImageSize {
  BIG = 'BIG',
  SMALL_100 = 'SMALL_100',
  SMALL_200 = 'SMALL_200',
  SMALL_300 = 'SMALL_300',
  SMALL_400 = 'SMALL_400',
  TINY_32 = 'TINY_32',
}

interface ProductPrice {
  price: number
  catalogPrice: number
  discount: number
  tax?: number
  timestamp?: string
  isLoading: boolean
  hasPrice: boolean
}

export interface ImageVariant {
  fileName: string
  order: number
  url: string
  alt?: string
}

abstract class Deserializable<T> {
  constructor(json?: T) {
    if (json) {
      Object.assign(this, json)
    }
  }
}

class ProductStocks
  extends Deserializable<MappedProductStocks>
  implements MappedProductStocks {
  private readonly store: Store<any>
  overallQuantity!: number
  selectedWarehousesQuantity!: number
  unit!: string
  warehouses!: StockWarehousesMapping
  isLoading!: boolean

  constructor(json: MappedProductStocks, store: Store<StocksState>) {
    super(json)
    this.store = store
  }

  get currentProduct(): Products.Product {
    const products = this.store.state.products.products
    // get product slug to identify product
    // @ts-ignore
    const slug = this.store.state.route.params.slug
    return Object.values(products).find((p: any) => p.slug === slug) as Products.Product
  }

  get currentWarehouse(): Warehouse | null {
    return this.store.getters['stocks/getWarehouse'](
      this.store.getters['stocks/getDefaultWarehouseId'],
    )
  }

  get parentWarehouse(): Warehouse | null {
    return (
      this.currentWarehouse &&
      this.store.getters['stocks/getWarehouse'](this.currentWarehouse.parent)
    )
  }

  get orderQuantityInParentWarehouse(): number {
    if (!this.currentProduct) {
      return 0
    }

    const defaultWarehouse = this.store.getters['stocks/getDefaultWarehouse']

    return this.store.getters['stocks/getProductOrderQuantityInWarehouse'](
      this.currentProduct.id,
      this.parentWarehouse?.id || defaultWarehouse.id,
    )
  }

  get orderQuantityInSelectedWarehouse(): number {
    if (!this.currentProduct) {
      return 0
    }

    return this.store.getters['stocks/getProductOrderQuantityInWarehouse'](
      this.currentProduct.id,
      this.store.getters['stocks/getDefaultWarehouse'].id,
    )
  }
}

export class Product extends Deserializable<Products.Product> implements Products.Product {
  additionalTechnicalDescription!: Record<string, string>
  attributes!: Array<Products.Attribute>
  canonicalCategory!: string
  catalogIndex!: string
  categoryPath!: Array<string>
  certificates!: Array<Products.ExternalUrl>
  currency!: Currency
  fullDescription!: string
  id!: string
  labels!: Array<string>
  manufacturer!: Products.Manufacturer
  manufacturerIndex!: string
  measurementUnits!: Products.MeasurementUnits
  name!: string
  netDimension!: Products.NetDimension
  photos!: Array<Products.ExternalUrl>
  photosWithVariants!: Array<Products.PhotoWithVariants>
  productDataSheets!: Array<Products.ExternalUrl>
  promotionsImg!: Array<string>
  relatedProducts!: Array<Products.RelatedProductsDto>
  relationsByAttribute!: Array<Products.RelationsByAttribute>
  seo!: Products.ProductSeo
  slug!: string
  surchargePriceAmount!: number
  brand?: Products.Brand
  characteristics!: Array<Suggestions.CharacteristicValue>
  shippingTime?: string
  shortDescription?: string
  usage?: string
  multivalueAttributes!: Array<Products.MultivalueAttribute>
  // @ts-ignore
  price!: ProductPrice
  stocks!: ProductStocks
  cannotOrderAboveStock!: boolean
  energyClass!: Products.EnergyClassDto
  manufacturerId!: string
  pluginsData!: Record<string, any>
  status!: 'ACTIVE' | 'DRAFT_NEW' | 'DRAFT_SENT' | 'DRAFT_REJECTED'
  videos!: Array<Products.ExternalUrl>

  contentUnitPrice() {
    return (
      priceFormatter(
        parseFloat(
          toPackagingQuantityPrice(this.price.price, this.measurementUnits.packingQuantity),
        ),
        this.currency,
      ) || null
    )
  }

  contentUnitCatalogPrice() {
    return (
      priceFormatter(
        parseFloat(
          toPackagingQuantityPrice(this.price.catalogPrice, this.measurementUnits.packingQuantity),
        ),
        this.currency,
      ) || null
    )
  }

  setCurrency(currency: Currency) {
    this.currency = currency
  }

  setPrice(price: ProductPrice) {
    this.price = price
  }

  setStocks(stocks: ProductStocks) {
    this.stocks = stocks
  }

  formattedCurrentPrice(): string | null {
    if (!this.price) {
      return null
    }
    return priceFormatter(this.price.price, this.currency) || null
  }

  formattedCatalogPrice(): string | null {
    if (!this.price) {
      return null
    }
    return priceFormatter(this.price.catalogPrice, this.currency) || null
  }

  hasCharacteristic(characteristic: string): boolean {
    return this.characteristics.some((data: Orderpath.App.Shared.CharacteristicData): boolean => {
      return data.characteristicCode === characteristic
    })
  }

  getCharacteristic(characteristic: string) {
    // @ts-ignore
    return this.characteristics.find(
      ({ characteristicCode }) => characteristicCode === characteristic,
    )
  }

  hasLabel(label: string) {
    return this.labels.includes(label)
  }

  getLabel(label: string) {
    return this.labels.find((item: string) => item === label)
  }

  getPhotosInVariant(size: ProductImageSize): Array<ImageVariant> {
    const isVariant = (variant: ImageVariant | null): variant is ImageVariant => {
      return variant !== null
    }
    return this.photosWithVariants
      .map((variant) => {
        if (variant.variantMap && variant.variantMap[size]) {
          return {
            fileName: variant.fileName,
            order: variant.order,
            url: variant.variantMap[size],
            alt: variant?.alt || this.name,
          }
        }
        return null
      })
      .filter(isVariant) as Array<ImageVariant>
  }

  getPhotoInVariant(index: number, size: ProductImageSize): string {
    return this.photosWithVariants[index]?.variantMap[size]
  }

  getPlaceholderImage(): ExternalUrl {
    // TODO: Zastanowić się gdzie to dać -> aktualnie jest też wrzucane do photos[] w oneCore
    return {
      fileName: 'photo',
      url: 'https://static-demo-images.s3-eu-west-1.amazonaws.com/photo_plcs_min.svg',
      order: 1,
      placeholder: true,
    }
  }

  hasPhotos(): boolean {
    return this.photosWithVariants.length > 0
  }

  getOrderUnitDescription(): string | undefined {
    return this.measurementUnits?.orderUnitDescription
  }
}

const layout: BindingHelpers = namespace('layout')
const products: BindingHelpers = namespace('products')
const stocks: BindingHelpers = namespace('stocks')

@Component
export default class CurrentProductMixin extends Vue {
  @products.Action fetchProductWithPriceAndStock: any
  @products.Getter getProduct: any
  @products.Getter getProductPrice: any
  @products.Getter getProductImages: any
  @layout.Getter isGrossSelected: any
  @layout.Getter getCurrency: any
  @stocks.Getter getProductStocks: any

  @InjectReactive({
    from: 'currentProduct',
    default: null,
  })
  currentProduct!: Products.Product | null

  get $product(): Product | null {
    if (this.currentProduct) {
      const product = new Product(this.currentProduct)
      product.setCurrency(this.getCurrency)
      const productPrice: any | null = this.getProductPrice(
        this.currentProduct.id,
        this.isGrossSelected,
      )
      product.setPrice({
        ...(productPrice !== null ? productPrice : {}),
        hasPrice: productPrice !== null,
        isLoading: this.$wait.is(`product-${this.currentProduct.id}-price-loading`),
      })
      product.setStocks(
        new ProductStocks(
          {
            ...this.getProductStocks(this.currentProduct.id),
            isLoading: this.$wait.is(`product-${this.currentProduct.id}-stocks-loading`),
          },
          this.$store,
        ),
      )
      return product
    }
    return null
  }
}
